@mixin textBold45 {
    font-family: 'MuseoSansCyrl-900';
    font-size: 45px;
    line-height: 140%;
}
@mixin textBold57 {
    font-family: 'MuseoSansCyrl-900';
    font-size: 57px;
    line-height: 140%;
}
@mixin textBold25 {
    font-family: 'MuseoSansCyrl-700';
    font-size: 25px;
    line-height: 140%;
}
@mixin textBold18 {
    font-family: 'MuseoSansCyrl-700';
    font-size: 18px;
    line-height: 140%;
}
@mixin textBold19 {
    font-family: 'MuseoSansCyrl-700';
    font-size: 19px;
    line-height: 140%;
}
@mixin textNormal25 {
    font-family: 'MuseoSansCyrl-500';
    font-size: 25px;
    line-height: 140%;
}
@mixin textLight18 {
    font-family: 'MuseoSansCyrl-300';
    font-size: 18px;
    line-height: 140%;
}
@mixin textLight16 {
    font-family: 'MuseoSansCyrl-300';
    font-size: 16px;
    line-height: 140%;
}

@mixin bgRed {
    background: #FF0000;
}
@mixin bgGrey {
    background: #656565;
}
@mixin bgDark {
    background: #101418;
}
@mixin bgDark_light {
    background: #232930;
}
@mixin h1 {
    font-family: 'MuseoSansCyrl-900';
    font-size: 57px;
    line-height: 140%;
    color: #101418;
    text-transform: uppercase;
    @media screen and (max-width: 992px){
        font-size: 38px;
        line-height: 108%;
        letter-spacing: 0.03em;
    }
}

// @media screen and (max-width: 480px){}