// resetCss
@import "css-reset";

@import "global";
// mixins
@import "mixin";

// Fonts
@import "../fonts/Museo/stylesheet";

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";


.main-banner-block {
    height: 910px;
    @include bgDark;
    position: relative;
    overflow: hidden;
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    @media screen and (max-width: 1240px) {
        min-height: auto;
    }
    @media screen and (max-width: 991px) {
        background: #f8f8f8;
        padding-top: 142px;
        height: auto;
    }
    .mobile-banner {
        display: none;
        @media screen and (max-width: 991px) {
            display: flex;
            width: 100%;
            max-width: 710px;
            padding: 0 15px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 80px;
        }
        .big-img {
            width: 100%;
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
            }
            .description {
                @include textBold19;
                padding-top: 10px;
                @media screen and (max-width: 480px){
                    @include textLight18;
                }
            }
        }
        .flex-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            align-items: flex-start;
            .small-img {
                display: flex;
                flex-direction: column;
                width: calc(50% - 5px);
                img {
                    height: 100%;
                    max-width: 100%;
                }
                .description {
                    @include textBold19;
                    padding-top: 10px;
                    @media screen and (max-width: 480px){
                        @include textLight18;
                    }
                }
            }
        }
    }
    .main-video {
        height: 910px;
        max-width: none;
        transform-origin: center;
        transform: scale(1.5);
        position: absolute;
        @media screen and (max-width: 1240px) {
            height: 788px;
        }
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    .bricks-block__row {
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 991px) {
            display: none;
        }
        &:after {
            content: "";
            display: block;
            border-bottom: 1px solid rgba(220, 220, 220, 0.5);
            width: 100%;
            position: absolute;
            height: 0px;
            top: calc(50% + 50px);
            z-index: -1;
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
        .brick-item {
            width: 760px;
            height: 100%;
            transform: skew(-15deg);
            display: flex;
            flex-direction: column;
            &:first-child {
                border-left: 1px solid rgba(220, 220, 220, 0.5);
                border-right: 1px solid rgba(220, 220, 220, 0.5);
            }
            &:last-child {
                border-right: 1px solid rgba(220, 220, 220, 0.5);
            }
        }
        img {
            margin-left: -40px;
            width: 880px;
            top: 0;
            visibility: hidden;
            transform: skew(15deg);
            z-index: 2;
            position: absolute;
            @media screen and (max-width: 1200px) {
                margin-left: -50px;
                width: 740px;
            }
        }
        .brick-item_small {
            color: #fff;
            width: 100%;
            height: 50%;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: flex-end;
            .line {
                position: absolute;
                display: block;
                height: 100%;
                top: 0;
                border-left: 1px solid rgba(220, 220, 220, 0.5);
                left: 50%;
                z-index: -1;
            }
            &:hover {
                img {
                    visibility: visible;
                }
            }
        }
        .brick-item-text {
            @include textBold45;
            color: #fff;
            z-index: 3;
            text-transform: uppercase;
            position: relative;
            transform: skew(15deg);
            margin-top: auto;
            margin-left: auto;
            padding: 0 25px;
            // @media screen and (max-width: 1840px) {
            //     width: 420px;
            // }
            // @media screen and (max-width: 1240px) {
            //     font-size: 30px;
            //     width: 360px;
            // }
        }
    }
}
.about-company {
    overflow: hidden;
    position: relative;
    padding: 80px 0 160px 0;
    @media screen and (max-width: 991px) {
        padding-bottom: 80px;
        padding-top: 0;
    }
    .romb-block {
        margin-left: 38%;
        height: 934px;
        width: 1553px;
        background: #eeebeb;
        transform: skew(-10deg, -10deg) rotate(10deg);
        @media screen and (max-width: 1580px) {
            height: 700px;
        }
        @media screen and (max-width: 1440px) {
            height: 580px;
        }
        @media screen and (max-width: 1140px) {
            margin-left: 30%;
        }
        @media screen and (max-width: 991px) {
            position: absolute;
            margin-left: 10%;
            bottom: 280px;
            height: 760px;
        }
        @media screen and (max-width: 768px) {
            bottom: 380px;
            height: 580px;
        }
        @media screen and (max-width: 480px) {
            height: 460px;
        }
        @media screen and (max-width: 380px) {
            height: 380px;
        }
    }
    .wrapper {
        position: absolute;
        top: 73px;
        bottom: 80px;
        left: 0;
        right: 0;
        margin: auto;
        padding-top: 72px;
        display: flex;
        @media screen and (max-width: 991px) {
            position: static;
            flex-direction: column;
            align-items: center;
            padding-top: 0;
        }
        .left-col {
            width: 50%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 991px) {
                width: 100%;
                align-items: center;
                margin-bottom: 20px;
            }
            .h1-title {
                @include textBold57;
                width: 700px;
                margin-left: auto;
                position: relative;
                color: #101418;
                @media screen and (max-width: 1580px) {
                    font-size: 47px;
                }
                @media screen and (max-width: 1480px) {
                    width: auto;
                    margin-left: 50px;
                }
                @media screen and (max-width: 991px) {
                    width: 100%;
                }
                &:after {
                    content: "";
                    position: absolute;
                    height: 6px;
                    @include bgRed;
                    width: 100%;
                    bottom: -11px;
                    left: -62%;
                }
            }
            ul {
                margin-top: 40px;
                width: 700px;
                margin-left: auto;
                @media screen and (max-width: 1480px) {
                    width: auto;
                    margin-left: 50px;
                    padding-right: 25px;
                }
                @media screen and (max-width: 480px) {
                    width: 100%;
                    padding: 0 25px 0 25px;
                    margin-left: 0;
                }
                li {
                    @include textNormal25;
                    position: relative;
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 25px;
                    @media screen and (max-width: 1580px) {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                    @media screen and (max-width: 480px){
                        @include textLight18;
                    }
                }
            }
        }
        .right-col {
            width: 50%;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 1440px) {
                padding-right: 25px;
            }
            @media screen and (max-width: 991px) {
                width: 100%;
                padding: 0 25px;
                align-items: center;
            }

            .image-block {
                width: 931px;
                height: 730px;
                overflow: hidden;
                margin-left: auto;
                img {
                    max-width: 100%;
                }
                @media screen and (max-width: 1580px) {
                    width: 680px;
                    height: 500px;
                    img {
                        margin-top: -120px;
                    }
                }
                @media screen and (max-width: 1440px) {
                    width: 532px;
                    height: auto;
                    // margin-right: 25px;
                    img {
                        width: 100%;
                        margin-top: 0;
                    }
                }
                @media screen and (max-width: 1140px) {
                    width: 100%;
                }
                @media screen and (max-width: 991px) {
                    z-index: 1;
                    max-width: 80%;
                    margin: 0;
                }
                @media screen and (max-width: 480px) {
                    max-width: 100%;
                }
            }
            form {
                margin-top: 65px;
                width: 100%;
                margin-left: auto;
                z-index: 1;
                @media screen and (max-width: 1440px) {
                    position: absolute;
                    bottom: 0;
                    width: 680px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                @media screen and (max-width: 991px) {
                    position: static;
                    margin-top: 130px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 360px;
                }
                @media screen and (max-width: 480px) {
                    width: 100%;
                }
                .form-title {
                    @include textBold18;
                    margin-bottom: 50px;
                    @media screen and (max-width: 768px) {
                        margin-bottom: 30px;
                    }
                    
                }
                .input-wrapper {
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        width: 100%;
                    }
                    label {
                        display: flex;
                        margin-right: 35px;
                        position: relative;
                        @media screen and (max-width: 768px) {
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 35px;
                        }
                    }
                    .inpItem {
                        width: 192px;
                        height: 25px;
                        border: none;
                        border-bottom: 1px solid #656565;
                        background: none;
                        @include textLight18;
                        @media screen and (max-width: 768px) {
                            width: 100%;
                        }
                    }
                    .sendBtn {
                        width: 200px;
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        border: none;
                        @include bgRed;
                        @include textBold19;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.manufacturing-block {
    padding: 80px 0;
    @media screen and (max-width: 991px) {
        padding-top: 0px;
    }
    .section-title {
        @include textBold57;
        margin-bottom: 100px;
        position: relative;
        width: 100%;
        text-transform: uppercase;
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            height: 6px;
            @include bgRed;
            width: 70%;
            bottom: -11px;
            left: -10%;
        }
    }
    img {
        width: 100%;
    }
}
.catalog-block {
    padding: 80px 0 176px 0;
    @media screen and (max-width: 991px) {
        padding-top: 0;
        padding-bottom: 50px;
    }
    .section-title {
        @include textBold57;
        margin-bottom: 50px;
        position: relative;
        width: 100%;
        text-transform: uppercase;
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            height: 6px;
            @include bgRed;
            width: 60%;
            bottom: -11px;
            right: -10%;
        }
    }
    .slider {
        .slider-item {
            width: 575px;
            height: 683px;
            background: #101418;
            @media screen and (max-width: 1760px) {
                width: 520px;
                height: 630px;
            }
            @media screen and (max-width: 1610px) {
                width: 470px;
                height: 580px;
            }
            @media screen and (max-width: 1450px) {
                width: 420px;
                height: 530px;
            }
            @media screen and (max-width: 1440px) {
                height: auto;
            }
            .image-block {
                width: 100%;
                height: 553px;
                overflow: hidden;
                @media screen and (max-width: 1760px) {
                    height: 500px;
                }
                @media screen and (max-width: 1610px) {
                    height: 450px;
                }
                @media screen and (max-width: 1450px) {
                    height: 400px;
                }
                @media screen and (max-width: 1440px) {
                    height: auto;
                    overflow: visible;
                }
                img {
                    transition: 0.3s;
                    width: 100%;
                }
                &:hover {
                    img {
                        @media screen and (min-width: 1440px) {
                            transform: scale(1.1);
                            transition: 0.3s;
                        }
                    }
                }
            }
            .description {
                padding: 30px 20px;
                @include textBold25;
                font-size: 40px;
                color: #f8f8f8;
                @media screen and (max-width: 1450px) {
                    padding: 10px 20px;
                }
                @media screen and (max-width: 1440px) {
                    padding: 30px 20px;
                }
                @media screen and (max-width: 991px) {
                    padding: 10px;
                }
                @media screen and (max-width: 680px) {
                    @include textBold19;
                }
            }
        }
    }
    .swiper-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .nav-wrapper {
        display: none;
        @media screen and (max-width: 1440px) {
            position: relative;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 35px;
            .nav-btn {
                width: 40px;
                height: 40px;
                border: 1px solid black;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-child {
                    margin-right: 40px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border: 2px solid red;
                    transform-origin: center;
                    transform: rotate(45deg);
                }
                &.swiper-button-disabled {
                    &::after {
                        border-color: #656565;
                    }
                }
                &.nav-btn_prev {
                    &::after {
                        border-top: none;
                        border-right: none;
                    }
                }
                &.nav-btn_next {
                    &::after {
                        border-bottom: none;
                        border-left: none;
                    }
                }
            }
        }
    }
}
.masonry-block {
    padding-top: 136px;
    padding-bottom: 138px;
    background: #eeebeb;
    @media screen and (max-width: 991px) {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    .section-title {
        @include textBold57;
        margin-bottom: 100px;
        position: relative;
        width: 100%;
        text-transform: uppercase;
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            height: 6px;
            @include bgRed;
            width: 25%;
            bottom: -11px;
            left: -10%;
            @media screen and (max-width: 480px) {
                width: 60%;
            }
        }
    }
    .masonry-item {
        position: relative;
    }
    .img-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
        @media screen and (max-width: 480px) {
            margin-bottom: 50px;
        }
        .left-col,
        .right-col {
            
            @media screen and (max-width: 1024px) {
                width: 100%;
            }
            .text {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                @include textBold45;
                color: #fff;
                text-transform: uppercase;
                padding: 0 15px;
                text-align: center;
                padding-bottom: 20px;
                &_small {
                    @include textBold25;
                    @media screen and (max-width: 640px) {
                        font-size: 18px;
                    }
                    @media screen and (max-width: 440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 380px) {
                        font-size: 14px;
                    }
                }
                &_grey {
                    color: #1a1a1a;
                    align-items: center;
                    padding-bottom: 0;
                }
            }
            img {
                width: 100%;
            }
        }
        .left-col {
            width: calc(36% - 6px);
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 12px;
            }
            img {
                transition: 0.3s;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                    transition: 0.3s;
                }
            }
        }
        .right-col {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(64% - 6px);
            @media screen and (max-width: 768px) {
                width: 100%;  
            }
            .right-col__row {
                width: 100%;
                height: calc(50% - 6px);
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 12px;
                }
                .masonry-item {
                    width: calc(33% - 6px);
                    overflow: hidden;
                    @media screen and (max-width: 1024px) {
                        width: calc(50% - 6px);
                    }

                    &.two-colums{
                        width: calc(66% - 6px);
                        @media screen and (max-width: 1024px) {
                            width: 100%;
                            height: 60px;
                        }
                    }
                    img {
                        width: 100%;
                        transition: 0.3s;
                        height: 100%;
                    }
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: 0.3s;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    form {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        @media screen and (max-width: 1140px) {
            flex-direction: column;
        }
        .form-title {
            @include textBold18;
            margin-right: 80px;
            @media screen and (max-width: 1140px) {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            label {
                display: flex;
                margin-right: 35px;
                position: relative;
                @media screen and (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 35px;
                }
            }
            .inpItem {
                width: 192px;
                height: 25px;
                border: none;
                border-bottom: 1px solid #656565;
                background: none;
                @include textLight18;
                @media screen and (max-width: 768px) {
                    width: 310px;
                }
            }
            .sendBtn {
                width: 200px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                border: none;
                @include bgDark;
                @include textBold19;
                color: #fff;
                @media screen and (max-width: 768px) {
                    width: 310px;
                }
            }
        }
    }
}
.info-block {
    padding-top: 176px;
    position: relative;
    height: 1130px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1560px) {
        padding-top: 50px;
        height: 1000px;
    }
    @media screen and (max-width: 1320px) {
        height: 960px;
    }
    @media screen and (max-width: 991px) {
        height: auto;
        padding-bottom: 80px;
    }
    .img-block_big {
        overflow: hidden;
        position: absolute;
        top: 46px;
        overflow: hidden;
        right: 0;
        @media screen and (max-width: 1560px) {
            height: 780px;
        }
        @media screen and (max-width: 1320px) {
            height: 758px;
            right: -200px;
        }
        @media screen and (max-width: 991px) {
            position: static;
            width: 80%;
            height: auto;
            margin-left: auto;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                display: flex;
                height: 300px;
                background: rgba(35, 41, 48, 0.2);
                backdrop-filter: blur(20px);
                left: 0;
                top: 64%;
                z-index: -1;
            }
        }
        @media screen and (max-width: 540px) {
            &::after {
                height: 200px;
                top: 71%;
            }
        }

        @media screen and (max-width: 480px) {
            &::after {
                height: 180px;
                top: 70%;
            }
        }
        img {
            width: 100%;
            clip-path: polygon(30.3% 0,100% 0,100% 100%,0 100%);
        }
    }
    .img-block_small {
        overflow: hidden;
        height: 340px;
        position: absolute;
        bottom: 86px;
        right: 400px;
        @media screen and (max-width: 1560px) {
            bottom: 80px;
            right: 330px;
        }
        @media screen and (max-width: 1320px) {
            width: 520px;
            height: 270px;
            right: 240px;
            bottom: 115px;
        }
        @media screen and (max-width: 991px) {
            right: auto;
            left: 50px;
            width: 520px;
            height: 270px;
            bottom: 30px;
        }
        @media screen and (max-width: 680px) {
            width: 375px;
            height: 270px;
            bottom: -25px;
        }
        @media screen and (max-width: 480px) {
            width: 200px;
            height: 107px;
            bottom: 50px;
        }
        img {
            width: 100%;
            clip-path: polygon(14% 0,100% 0,86% 100%,0 100%);
        }
    }
    .section-title {
        @include textBold57;
        position: relative;
        width: calc(100% - 200px);
        margin-left: auto;
        text-transform: uppercase;
        margin-bottom: 80px;
        &:after {
            content: "";
            position: absolute;
            height: 6px;
            @include bgRed;
            width: 100%;
            bottom: -11px;
            left: -60%;
        }
        @media screen and (max-width: 1760px) {
            width: calc(100% - 100px);
        }
        @media screen and (max-width: 1640px) {
            width: calc(100% - 15px);
        }
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
            width: 100%;
            padding-left: 25px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
    }
    ul {
        width: calc(100% - 200px);
        margin-left: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 1760px) {
            width: calc(100% - 100px);
        }
        @media screen and (max-width: 1640px) {
            width: calc(100% - 30px);
        }
        @media screen and (max-width: 1080px) {
            margin-left: 30px;
            width: 47%;
        }
        @media screen and (max-width: 991px) {
            width: calc(100% - 50px);
            margin-left: 50px;
            padding-right: 15px;
        }
        @media screen and (max-width: 480px) {
            margin-left: 25px;
        }
        li {
            @include textNormal25;
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-bottom: 25px;
            z-index: 1;

            @media screen and (max-width: 640px) {
                @include textLight18;
                margin-bottom: 18px;
            }
            // Оля попросила убрать точки
            // &:before {
            //     content: "\2022";
            //     position: absolute;
            //     left: -17px;
            // }
        }
        &:after {
            content: "";
            display: flex;
            width: 100%;
            position: absolute;
            height: 559px;
            left: 0;
            margin-top: -25px;
            background: rgba(35, 41, 48, 0.2);
            backdrop-filter: blur(20px);
            @media screen and (max-width: 1320px) {
                height: 267px;
            }
            @media screen and (max-width: 991px) {
                display: none;
            }
        }
    }
}

.input-wrapper {
    label {
        position: relative;
        span.error {
            position: absolute;
            font-size: 12px;
            color: red;
            left: 0;
            bottom: -24px;
            white-space: nowrap;
        }
        input.error {
            border-color: red !important;
        }
    }
}

.brend-block {
    padding: 100px 0;
    @media screen and (max-width: 480px){
        padding: 50px 0;
    }
    .section-title {
        @include textBold57;
        margin-bottom: 100px;
        position: relative;
        width: 100%;
        max-width: 80%;
        text-transform: uppercase;
        
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            height: 6px;
            @include bgRed;
            width: 25%;
            bottom: -11px;
            left: -10%;
            @media screen and (max-width: 480px) {
                width: 60%;
            }
        }
    }
    .brend-slider {
        .slider-item {
            width: 575px;
            // height: 683px;
            
            @media screen and (max-width: 1760px) {
                width: 520px;
                // height: 630px;
            }
            @media screen and (max-width: 1610px) {
                width: 470px;
                // height: 580px;
            }
            @media screen and (max-width: 1450px) {
                width: 420px;
                // height: 530px;
            }
            @media screen and (max-width: 1440px) {
                height: auto;
            }
            .image-block {
                width: 100%;
                // height: 330px;
                overflow: hidden;
                @media screen and (max-width: 1760px) {
                    // height: 500px;
                }
                @media screen and (max-width: 1610px) {
                    // height: 450px;
                }
                @media screen and (max-width: 1450px) {
                    // height: 400px;
                }
                @media screen and (max-width: 1440px) {
                    height: auto;
                    overflow: visible;
                }
                img {
                    transition: 0.3s;
                    width: 100%;
                }
                &:hover {
                    img {
                        @media screen and (min-width: 1440px) {
                            transform: scale(1.1);
                            transition: 0.3s;
                        }
                    }
                }
            }
            .description {
                padding: 30px 20px;
                @include textBold25;
                color: #f8f8f8;
                height: 130px;
                background: #101418;
                @media screen and (max-width: 1450px) {
                    padding: 10px 20px;
                }
                @media screen and (max-width: 1440px) {
                    padding: 30px 20px;
                }
                @media screen and (max-width: 991px) {
                    padding: 10px;
                }
                @media screen and (max-width: 680px) {
                    @include textBold19;
                }
                @media screen and (max-width: 480px){
                    height: 70px;
                }
            }
        }
    }
    .swiper-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .nav-wrapper {
        position: relative;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        .nav-btn {
            width: 40px;
            height: 40px;
            border: 1px solid black;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
                margin-right: 40px;
            }
            &:after {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                border: 2px solid red;
                transform-origin: center;
                transform: rotate(45deg);
            }
            &.swiper-button-disabled {
                &::after {
                    border-color: #656565;
                }
            }
            &.nav-btn_prev {
                &::after {
                    border-top: none;
                    border-right: none;
                }
            }
            &.nav-btn_next {
                &::after {
                    border-bottom: none;
                    border-left: none;
                }
            }
        }
    }
}
.brend-block-info{
    margin-bottom: 100px;
    @media screen and (max-width: 480px){
        margin-bottom: 0;
    }
    .section-title{
        @include textBold57;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 100px;
        @media screen and (max-width: 991px) {
            font-size: 38px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 480px) {
            font-size: 28px;
            margin-bottom: 50px;
        }
    }
    .img-block{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @media screen and (max-width: 580px){
            flex-direction: column;
        }
        img{
            margin-right: 100px;
            height: 500px;
            max-height: 250px;
            @media screen and (max-width: 1200px){
                height: auto;
                width: 50%;
            }
            @media screen and (max-width: 991px){
                margin-right: 30px;
            }
            @media screen and (max-width: 580px){
                margin-right: 0;
                margin-bottom: 25px;
                width: 100%;
                max-width: 380px;
            }
            @media screen and (max-width: 480px){
                max-width: 100%;
            }
        }
        ul{
            @media screen and (max-width: 580px){
                width: 100%;
                max-width: 380px;
            }
            @media screen and (max-width: 480px){
                max-width: 100%;
                padding-left: 10px;
            }
            li{
                @include textNormal25;
                margin-bottom: 20px;
                @media screen and (max-width: 768px){
                    margin-bottom: 10px;
                    @include textLight18;
                }
            }
        }
    }
    
    
}
.brend-block-info__text{
    max-width: 480px;
    @include textLight18;
    @media screen and (max-width: 480px){
        @include textLight16;
    }
}
